exports.components = {
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sample-queries-index-js": () => import("./../../../src/pages/sample-queries/index.js" /* webpackChunkName: "component---src-pages-sample-queries-index-js" */),
  "component---src-pages-sample-queries-strapi-sample-queries-slug-js": () => import("./../../../src/pages/sample-queries/{strapiSampleQueries.Slug}.js" /* webpackChunkName: "component---src-pages-sample-queries-strapi-sample-queries-slug-js" */),
  "component---src-pages-tutorials-index-js": () => import("./../../../src/pages/tutorials/index.js" /* webpackChunkName: "component---src-pages-tutorials-index-js" */),
  "component---src-pages-tutorials-strapi-tutorials-slug-js": () => import("./../../../src/pages/tutorials/{strapiTutorials.Slug}.js" /* webpackChunkName: "component---src-pages-tutorials-strapi-tutorials-slug-js" */),
  "component---src-pages-unauthorized-index-js": () => import("./../../../src/pages/unauthorized/index.js" /* webpackChunkName: "component---src-pages-unauthorized-index-js" */)
}

